import React, { useState } from 'react';
import { Button, Table } from "antd";
import useOpenable from '../hooks/useOpenable';
import ToolModal from '../components/ToolModal';

const dataSource = [
  {
    key: '1',
    name: 'get_weather',
    description: 'Called when the user asks about the weather. This function will return the weather for the given location.',
  },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
];

function Tools() {
  const [isOpen, open, close] = useOpenable()
  const [record, setRecord] = useState(null)
  const onRow = (record, rowIndex) => {
    return {
      onClick: (e) => {
        setRecord(record)
        open()
      },
    };
  }

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Tools</h2>
        <Button type='primary' onClick={open}>Create New</Button>
      </div>
      <Table
        dataSource={dataSource}
        columns={columns}
        onRow={onRow}
      />
      <ToolModal
        isModalOpen={isOpen}
        closeModal={close}
        data={record}
      />
    </div>
  );
}

export default Tools;
