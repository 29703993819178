import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Spin, Tabs, Input } from 'antd';
import TalkButton from '../components/TalkButton';
import { supabase } from '../lib/supabase';

const { TextArea } = Input;
const defaultPrompt = 'You are a voice assistant. Your interface with users will be voice. You should use short and concise responses, and avoiding usage of unpronouncable punctuation.'

const codeStyle = {
  backgroundColor: '#f5f5f5',
  padding: '2px 4px',
  fontFamily: 'monospace',
  borderRadius: '4px',
  color: '#d63384',
};

const useAssistant = (id) => {
  const [assistant, setAssistant] = useState(null)

  async function getAssistantById(id) {
    const { data, error } = await supabase
      .from('assistants')
      .select('*')
      .eq('id', id)
      .single(); // .single() returns one row
  
    if (error) {
      console.error('Error fetching assistant:', error);
      return null;
    }
  
    setAssistant(data);
  }

  useEffect(() => {
    getAssistantById(id)
  }, [id])

  return { assistant }
}

function AssistantDetail() {
  const { assistantId } = useParams();
  const { assistant } = useAssistant(assistantId)

  if (!assistant) return <Spin />

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>{assistant?.name}</h2>
        <TalkButton />
      </div>
      <code style={codeStyle}>{ assistantId }</code>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: 'Prompt',
            key: '1',
            children: <Prompt assistant={assistant} />,
          },
          {
            label: 'Knowledge Base',
            key: '2',
            children: 'KB',
            disabled: true,
          },
          {
            label: 'Functions',
            key: '3',
            children: 'Functions',
            disabled: true,
          },
          {
            label: 'Deploy',
            key: '4',
            children: <Deploy assistant={assistant} />,
          },
        ]}
      />
    </div>
  )
}

function Prompt({ assistant }) {
  const [inputGreeting, setInputGreeting] = useState(assistant.greeting)
  const [inputPrompt, setInputPrompt] = useState(assistant.prompt)

  const updateAssistant = async () => {
    await supabase
      .from('assistants')
      .update({
        greeting: inputGreeting,
        prompt: inputPrompt,
      })
      .eq('id', assistant.id)
      .select()
  }

  return (
    <div>
      <h3>Greeting</h3>
      <TextArea
        rows={2}
        value={inputGreeting}
        onChange={e => setInputGreeting(e.target.value)}
      />
      <h3>Agent Instructions</h3>
      <TextArea
        rows={6}
        value={inputPrompt}
        onChange={e => setInputPrompt(e.target.value)}
      />
      <br /><br />
      <Button
        type="primary"
        onClick={updateAssistant}
      >
        Save
      </Button>
    </div>
  );
}

function Deploy({ assistant }) {
  return (
    <div>
      hi
    </div>
  )
}

export default AssistantDetail;
