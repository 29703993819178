import React from 'react';
import { Button, Drawer } from 'antd';
import {
  LiveKitRoom,
  RoomAudioRenderer,
  useLocalParticipant,
  useRemoteParticipants,
  useTracks,
} from '@livekit/components-react';
import {
  RoomEvent,
  Track,
} from "livekit-client";
import { TranscriptionTile } from './TranscriptionTile';

export default function AgentDrawer(props) {
  const { isOpen, onClose, token, serverUrl } = props

  if (!token) return null;

  return (
    <LiveKitRoom
      token={token}
      serverUrl={serverUrl}
      connectOptions={{autoSubscribe: true}}
    >
      <Drawer
        title="Calling"
        placement="right"
        width={500}
        onClose={onClose}
        open={isOpen}
        extra={<MicButton />}
      >
        <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
          <div style={{flex: 1}}>
            <Transcription />
          </div>
          <Button type="primary" danger>Hang up</Button>
        </div>
        <RoomAudioRenderer />
      </Drawer>
    </LiveKitRoom>
  )
}

const MicButton = () => {
  const { localParticipant, isMicrophoneEnabled } = useLocalParticipant();
  const toggleMic = () => {
    localParticipant?.setMicrophoneEnabled(!isMicrophoneEnabled)
  }

  return (
    <Button
      type={ isMicrophoneEnabled ? null : 'primary' }
      onClick={toggleMic}
    >
      { isMicrophoneEnabled ? 'Mute' : 'Unmute' }
    </Button>
  )
}

const Transcription = () => {
  const tracks = useTracks();
  const participants = useRemoteParticipants({
    updateOnlyOn: [RoomEvent.ParticipantMetadataChanged],
  });
  const agentParticipant = participants.find((p) => p.isAgent);

  let agentAudioTrack;
  const aat = tracks.find(
    (trackRef) =>
      trackRef.publication.kind === Track.Kind.Audio &&
      trackRef.participant.isAgent
  );
  if (aat) {
    agentAudioTrack = aat;
  } else if (agentParticipant) {
    agentAudioTrack = {
      participant: agentParticipant,
      source: Track.Source.Microphone,
    };
  }

  if (!agentAudioTrack) return <></>;

  return <TranscriptionTile agentAudioTrack={agentAudioTrack} />
}
