import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, SettingOutlined, MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, Layout, Space } from 'antd';

const { Header } = Layout;

const AppHeader = ({ toggleNav, color }) => {
  const navigate = useNavigate();

  return (
    <Header style={headerStyle(color)}>
      <Space size="middle">
        <Button size='large' type="text" icon={<MenuOutlined />} onClick={toggleNav} />
        <Button disabled type="primary" icon={<PlusOutlined />}>Create</Button>
      </Space>
      <Input disabled style={{width: 420}} size="large" placeholder="Search..." prefix={<SearchOutlined />} variant="filled" />
      <Button disabled size='large' type="text" icon={<SettingOutlined />} onClick={() => navigate('settings')} />
    </Header>
  )
}

export default AppHeader

const headerStyle = (color) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px',
  background: color,
  borderBottom: '1px solid #dedfe2'
})
