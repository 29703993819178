export const ChatMessage = ({
  name,
  message,
  hideName,
}) => {
  return (
    <div>
      {!hideName && <div>{name}</div>}
      <div>{message}</div>
    </div>
  );
};
