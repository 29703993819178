import React, {useState} from 'react';
import { Breadcrumb, Layout, theme } from 'antd';
import AppHeader from './components/AppHeader';
import LeftNav from './components/LeftNav';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Assistants from './pages/Assistants';
import Files from './pages/Files';
import PhoneNumbers from './pages/PhoneNumbers';
import Tools from './pages/Tools';
import Flows from './pages/Flows';
import AssistantDetail from './components/AssistantDetail';

const { Content } = Layout;
const LEFTNAV_WIDTH = 211;

const App = () => {
  const { token } = theme.useToken();
  const { colorBgContainer, borderRadiusLG } = token
  const [collapsed, setCollapsed] = useState(false);
  const toggleNav = () => setCollapsed(!collapsed)

  return (
    <Router>
      <Layout style={{ display: 'flex', flexDirection: 'column', height: '100vh'}}>
        <AppHeader toggleNav={toggleNav} color={colorBgContainer} />
        <Layout style={{ flex: 1 }} hasSider>
          <LeftNav collapsed={collapsed} width={LEFTNAV_WIDTH} color={colorBgContainer} />
          <Layout style={{ display: 'flex', flexDirection: 'column', height: '100%'}}>
            <BreadCrumbLinks />
            <Content style={{ flex: 1, margin: '24px 16px', overflow: 'initial' }}>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  padding: 24,
                  background: colorBgContainer,
                  borderRadius: borderRadiusLG,
                }}
              >
                <Routes>
                  <Route path="/" element={<Navigate to="/assistants" replace />} />
                  <Route path="/assistants" element={<Assistants />} />
                  <Route path="/assistants/:assistantId" element={<AssistantDetail />} />
                  <Route path="/phones" element={<PhoneNumbers />} />
                  <Route path="/files" element={<Files />} />
                  <Route path="/tools" element={<Tools />} />
                  <Route path="/flows" element={<Flows />} />
                </Routes>
              </div>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </Router>
  );
};

export default App;

const BreadCrumbLinks = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const paths = pathname.split('/').filter((path) => path);

  const items = paths.map((path, index) => {
    const href = `/${paths.slice(0, index + 1).join('/')}`;
    return {
      title: path.charAt(0).toUpperCase() + path.slice(1), // Capitalize path
      href: href,
    };
  });

  return (
    <Breadcrumb
      style={{ margin: '24px 16px 0' }}
      separator=">"
      items={items}
    />
  )
}