import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table,
} from 'antd';
import { supabase } from '../lib/supabase';

const columns = [
  { title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'ID',
    dataIndex: 'id',
  },
  { title: 'Created at',
    dataIndex: 'created_at',
  }
];

function Assistants() {
  const navigate = useNavigate();
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to list files from the bucket
  const listAssistants = async () => {
    try {
      let { data: assistants, error } = await supabase
        .from('assistants')
        .select('*')

      if (error) throw error;

      setAssistants(assistants || []);
    } catch (error) {
      console.error('Error listing assistants:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listAssistants();
  }, []);

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => navigate(`/assistants/${record.id}`)
    }
  }


  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>Assistants</h2>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={assistants}
        onRow={onRow}
        size="large"
      />
    </div>
  );
}

export default Assistants;
