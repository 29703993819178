'use client';
import React, { useState } from 'react';
import { PhoneOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import useOpenable from '../hooks/useOpenable';
import AgentDrawer from './AgentDrawer';

const TalkButton = () => {
  const [isOpen, open, close] = useOpenable()
  const [token, setToken] = useState(null);
  const [url, setUrl] = useState(null);
  const connect = async () => {
    const {accessToken, url} = await fetch('/api/livekit_token').then(res => res.json());
    setToken(accessToken);
    setUrl(url);
    open()
  }

  return (
    <>
      <div>
        <Button
          type="primary"
          icon={<PhoneOutlined />}
          onClick={connect}
        >
         Test Assistant
        </Button>
      </div>
      <AgentDrawer
        isOpen={isOpen}
        onClose={() => close()}
        token={token}
        serverUrl={url}
      />
    </>
  )
}

export default TalkButton
