import React from 'react';
import { Modal } from 'antd';

const ToolModal = ({ isModalOpen, closeModal }) => {
  const handleOk = () => {
    closeModal();
  };

  const handleCancel = () => {
    closeModal();
  };

  return (
    <Modal title="Create/Edit Tool" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
      <p>Some contents...</p>
      <p>Some contents...</p>
      <p>Some contents...</p>
    </Modal>
  );
};

export default ToolModal;