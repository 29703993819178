import React from 'react';
import { Table } from "antd";

const dataSource = [
  {
    key: '1',
    phone: '+1 (503) 548-4361',
    agent: '-',
  },
  {
    key: '2',
    phone: '+1 (844) 257-6492',
    agent: '-',
  },
];

const columns = [
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Agent',
    dataIndex: 'agent',
    key: 'agent',
  },
];

function PhoneNumbers() {
  return (
    <div>
      <h2>Phone Numbers</h2>
      <Table dataSource={dataSource} columns={columns} />
    </div>
  );
}

export default PhoneNumbers;
