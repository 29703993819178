import React, { useEffect, useState } from 'react';
import { DownOutlined, UploadOutlined } from '@ant-design/icons';
import {
  Button,
  message,
  Space,
  Table,
  Upload
} from 'antd';
import { supabase } from '../lib/supabase';

const SUPABASE_PROJECT_ID = 'rkdqgvotxndaelbjrfjh'
const STORAGE_BUCKET = 'dash-files'


function Files() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  // Function to list files from the bucket
  const listFiles = async () => {
    try {
      const { data, error } = await supabase
        .storage
        .from(STORAGE_BUCKET) // Replace with your bucket name
        .list()

      if (error) throw error;

      setFiles(data || []);
    } catch (error) {
      console.error('Error listing files:', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    listFiles();
  }, []);

  const downloadFile = async (filename) => {
    const { data, error } = await supabase.storage
      .from(STORAGE_BUCKET)
      .download(filename)
  
      if (error) {
        console.error('Error downloading file:', error)
        return
      }
    
      // Create a URL to download the file
      const blobUrl = URL.createObjectURL(data)
    
      // Optionally, create a link to prompt download
      const link = document.createElement('a')
      link.href = blobUrl
      link.setAttribute('download', filename) // Set the filename for download
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
  }

  const deleteFile = async (filename) => {
    const { data, error } = await supabase.storage
      .from(STORAGE_BUCKET)
      .remove([filename])

    listFiles() //refresh
  }
  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    { title: 'Type',
      dataIndex: ['metadata', 'mimetype'],
    },
    { title: 'Created at',
      dataIndex: 'created_at',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      width: 200,
      fixed: 'right',
      render: (_, record) => {
        return (
          <Space size="middle">
            <a onClick={() => downloadFile(record.name)}>Download</a>
            <a onClick={() => deleteFile(record.name)}>Delete</a>
          </Space>
        )
      },
    },
  ];
  
  const uploadProps = {
    name: 'file',
    action: file => `https://${SUPABASE_PROJECT_ID}.supabase.co/storage/v1/object/${STORAGE_BUCKET}/${file.name}`,
    headers: supabase.auth.headers,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        
        listFiles() // refresh
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        <h2>File</h2>
        <Upload {...uploadProps}>
          <Button type='primary' icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={files}
        size="large"
      />
    </div>
  );
}

export default Files;
