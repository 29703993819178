import React from 'react';
import {
  FileOutlined,
  PhoneOutlined,
  ToolOutlined,
  UserOutlined,
  PartitionOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
// import logo from '../assets/logo.png'; 

const { Sider } = Layout;

const LeftNav = ({ collapsed, width, color }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const path = location.pathname;
  const basePath = path.split('/').slice(0, 3).join('/');
  
  const handleSelect = ({ key }) => {
    navigate(key);
  };

  const items = [
    {
      key: '/assistants',
      icon: React.createElement(UserOutlined),
      label: 'Assistants',
    },
    {
      key: '/phones',
      icon: React.createElement(PhoneOutlined),
      label: 'Phone Numbers',
    },
    {
      key: '/files',
      icon: React.createElement(FileOutlined),
      label: 'Files',
    },
    {
      key: '/tools',
      icon: React.createElement(ToolOutlined),
      label: 'Tools',
    },
    {
      key: '/flows',
      icon: React.createElement(PartitionOutlined),
      label: 'Flows',
      disabled: true,
    },
  ]

  
  return (
    <Sider collapsed={collapsed} width={width} style={siderStyle(color)}>
      {/* <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <img style={{width: 42, marginTop: 12}} src={logo} alt="Hello AI Logo" />
      </div>
      <Divider style={{margin: 12}} /> */}
      <Menu
        style={{ width: '100%' }}
        theme="light"
        mode="inline"
        selectedKeys={[basePath]}
        items={items}
        onSelect={handleSelect}
      />
    </Sider>
  )
}

export default LeftNav

const siderStyle = (color) => ({
  background: color,
  padding: '12px 0'
  // overflow: 'auto',
  // position: 'fixed',
  // insetInlineStart: 0,
  // top: 0,
  // bottom: 0,
  // scrollbarWidth: 'thin',
  // scrollbarColor: 'unset',
});